import React, { useEffect, useState, useMemo } from 'react';
import gtConfig from "../../config";


export default function GridTotalsHeader({extractionKey, fileType, data}){

    const [fieldTotalMap, setFieldTotalMap] = useState( new Set());

    const fields = useMemo( () => {
        const attribute = extractionKey.substring(0, extractionKey.indexOf('['));
        return gtConfig.aggregation[fileType][attribute];

    },[extractionKey, fileType]);

    useEffect(() => {    
        const newMap = new Map();
        for (let fieldObject of fields ){
            if ( data.length < 1){
                continue;
            }
            
            const field =  Object.keys(fieldObject)[0];
            const operation = Object.values(fieldObject)[0];
            const values = []
            for (let i = 1; i < data.length; i++){
                const index = data[0].indexOf(field);
                if (index < 0){
                    continue;
                }
                let newValue =  data[i][index][0];
                if (newValue && typeof newValue !== 'number'){
                    //Checking twice if the newValue is Array since in some cases the newValue is Array of Array!
                    newValue = Array.isArray(newValue) ? newValue[0] : newValue;
                    newValue = Array.isArray(newValue) ? newValue[0].trim() : newValue.trim();
                }
                if (typeof newValue !== 'number'){
                    newValue = newValue.replace(',', '').replace('$', '').replace('%', '');
                }
    
                newValue = parseFloat(newValue);
                if (!isNaN(newValue) && typeof newValue === 'number'){
                    values.push(newValue)
                }
            }
            if ( operation === 'sum'){
                newMap.set(`${field}(${operation})`, values.reduce((a, b) => a + b, 0));
            }
            
            if ( operation === 'max'){
                newMap.set(`${field}(${operation})`, Math.max(...values));
            }

            if ( operation === 'min'){
                newMap.set(`${field}(${operation})`, Math.min(...values));
            }

            if ( operation === 'avg'){
                const sum = values.reduce((a, b) => a + b, 0);
                const len = data.length - 1;
                const average =  sum / len;
                newMap.set(`${field}(${operation})`,average.toFixed(2));
            }
        }
        setFieldTotalMap(new Map(newMap));

    }, [data, fields]);


    return (
        <>
            <label style={{fontWeight: 'normal'}}> | totals: </label>
             { Array.from(fieldTotalMap).map(([key, value], index)=> (
                <label key={index} title={key} style={{cursor: 'pointer'}}>{value.toLocaleString('en-US')} { index < fieldTotalMap.size - 1 && '|'} </label>
             )) 
            }
        </>
    )
}
